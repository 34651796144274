.icons {
    font-size: 24px;
    color: #1260A7
}

.contact-row {
    cursor: pointer;
}

.contact-background {
    background-color: #E8E8E8
}