.heading-one {
    text-align: end;
}

.heading-two {
    text-align: start;
}

.text-color {
    color: #1260A7
}

.symbol {
    text-align: center;
}

.text-style {
    font-weight: 700;
    color: #1260A7
}

.new-line {
    white-space: pre-wrap;
}

.logo-border {
    border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.image-style {
    width: 16vw;
    height: auto,
}

@media only screen and (max-width: 770px) {
    .heading-one,
    .heading-two {
        text-align: center;
    }
    .image-style {
        width: 35vw;
    }
    .symbol {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .heading-one,
    .heading-two {
        text-align: center;
    }
    .symbol {
        display: none;
    }
}