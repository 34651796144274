.main-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
  padding-bottom: 100px;
}

.both-logo-bg {
  background-image: url("../../images/bg_both_logos.svg");
}

.ba-logo-bg {
  background-image: url("../../images/bg_ba_logo.svg");
}

.aa-logo-bg {
  background-image: url("../../images/bg_aa_logo.svg");
}
